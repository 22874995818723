<template>
  <div class="profile">
    <v-container>
      <component :is="templateName" :key="key" :page="page"></component>
    </v-container>
  </div>
</template>
<script>
import Static from "@/components/wordpress/Static.vue";
import Service from "@/components/wordpress/Service.vue";

import get from "lodash/get";
import startCase from "lodash/startCase";

import CMSService from "~/service/cmService";
export default {
  data() {
    return { page: {}, key: 1 };
  },
  jsonld() {
    if (this.page) {
      return {
        "@context": "https://schema.org",
        "@type": "NewsArticle",
        mainEntityOfPage: {
          "@type": "WebPage",
          "@id": this.page.link
        },
        headline: this.page.meta ? this.page.meta.seo_title : "",
        image: ["https://google.com/logo.jpg"],
        author: {
          "@type": "Organization",
          name: "Iperal"
        },
        datePublished: this.page.date,
        dateModified: this.page.date,
        publisher: {
          "@type": "Organization",
          name: "Iperal",
          logo: {
            "@type": "ImageObject",
            url: "https://google.com/logo.jpg"
          }
        }
      };
    }
  },
  created() {
    this.fetchPage(this.$route.params.pageName);
  },
  components: { Static, Service },
  computed: {
    templateName() {
      if (this.page.template) {
        let templateName = startCase(this.page.template);
        return templateName;
      } else {
        return "Static";
      }
    }
  },
  methods: {
    async fetchPage(pageName) {
      let data = await CMSService.getPage(pageName);
      if (data.length > 0) {
        this.page = data[0];
        this.key++;
      }
    }
  },
  metaInfo() {
    return {
      title: get(this.page, "meta.seo_title", "Pagina statica"),
      meta: [
        {
          vmid: "description",
          name: "description",
          content: get(this.page, "meta.seo_description")
        }
      ]
    };
  },
  watch: {
    "$route.params.pageName": function(pageName) {
      this.fetchPage(pageName);
    }
  }
};
</script>
