<template>
  <div class="cookie-bot-container">
    <div id="installedCookieTable"></div>
  </div>
</template>
<script>
export default {
  name: "cookieBotCookiePolicy",
  props: {},
  data() {
    return {
      template: null
    };
  },
  methods: {
    loadScript() {
      let script = document.createElement("script");
      script.setAttribute(
        "src",
        "https://consent.cookiebot.com/0a237c92-70af-46c9-b29a-bc27902b1af6/cd.js"
      );
      // `https://consent.cookiebot.com/${process.env.VUE_APP_COOKIEBOT_CBID}/cd.js`
      script.async = true;

      let htmlEelement = document.getElementById("installedCookieTable");
      htmlEelement.appendChild(script);
    }
  },
  mounted() {
    try {
      this.loadScript();
    } catch (err) {
      console.log(err);
    }
  }
};
</script>
