<template>
  <div class="service">
    <v-container>
      <service-autodiscovery />

      <h1 v-html="title"></h1>
      <div v-html="content" @click="clicked"></div>
      <!-- Inserire qui il widget che carica l'elenco dei punti vendita/dell'a casa/o dei locker, cercare ci prenderlo come parametro da wp-->
      <ServiceCoverage
        v-if="deliveryServiceId"
        :deliveryServiceId="deliveryServiceId"
      />
      <ServiceButtons
        v-if="deliveryServiceId"
        class="mb-5"
        :disabledButtons="[deliveryServiceId]"
        :title="$t('ServicesButtons.titleServicePage')"
      />
    </v-container>
  </div>
</template>
<style>
img.size-full {
  max-width: 100%;
  height: auto;
}
</style>
<script>
import ServiceAutodiscovery from "@/components/buttons/ServiceAutodiscovery.vue";
import ServiceCoverage from "@/components/buttons/ServiceCoverage.vue";
import ServiceButtons from "@/components/buttons/ServiceButtons";

import page from "~/mixins/page";
import clickHandler from "~/mixins/clickHandler";
import get from "lodash/get";
import toNumber from "lodash/toNumber";
export default {
  name: "Service",
  components: {
    ServiceAutodiscovery,
    ServiceCoverage,
    ServiceButtons
  },
  mixins: [page, clickHandler],
  computed: {
    title() {
      return get(this.page, "title.rendered");
    },
    deliveryServiceId() {
      return toNumber(this.page.deliveryserviceid);
    }
  },
  mounted() {
    // eslint-disable-next-line no-debugger
    // debugger;
    this.fetchPage(this.$route.params.pageName);
  }
};
</script>
