<template>
  <div class="mt-1">
    <h2>{{ this.$t("navbar.service.coverage.title") }}</h2>
    <div>
      {{ this.$t("navbar.service.coverage.subtitle") }}
    </div>
    <v-spacer></v-spacer>
    <v-card elevation="2" class="service-coverage-card">
      <div>
        <v-tabs
          background-color="white"
          light
          center-active
          v-model="tabSelected"
          show-arrows
        >
          <v-tabs-slider color="teal lighten-3"></v-tabs-slider>
          <v-tab v-for="(k, v) in addresses" :key="v">
            <strong>{{ $t("navbar.service.decodeProvice." + v) }}</strong>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tabSelected" class="pa-5">
          <v-tab-item v-for="(k, v) in addresses" :key="v">
            <div class="city-list">
              <span v-for="(item, index) in k" :key="item.id">
                <template v-if="index != 0">&nbsp;</template
                >{{ item.addressName || item.cityName }}</span
              >
            </div>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </v-card>
  </div>
</template>
<style scoped lang="scss">
.service-coverage-card {
  margin-top: 10px;
  margin-bottom: 30px;
  .v-tabs-slider-wrapper {
    height: 3px !important;
  }
  .v-tabs,
  .v-tabs-items {
    border-radius: 8px;
    .city-list {
      font-size: 15px;
      span:after {
        content: ",";
      }
      span:last-child:after {
        content: ".";
      }
    }
  }
}
// .city-list span {
//   display: inline-block;
// }
</style>
<script>
import AddressService from "~/service/addressService";
export default {
  name: "ServiceCoverage",
  props: { deliveryServiceId: { type: Number, required: true } },
  // watch: {
  //   deliveryServiceId: function(oldVal, val) {
  //     if (oldVal != val){
  //       vm.getAddressData()
  //     }
  //   }
  // },
  data() {
    return {
      tabSelected: null,
      addresses: []
    };
  },
  methods: {
    async fetchAddressData() {
      try {
        let address;
        if (this.deliveryServiceId == 2) {
          address = await AddressService.getHomeDeliveryList();
          this.addresses = this.groupByProvince(
            address.results,
            "provinceName"
          );
        } else {
          address = await AddressService.getAddressByDeliveryService(
            this.deliveryServiceId
          );
          this.addresses = this.groupByProvince(address.addresses, "province");
        }
      } catch (err) {
        console.log(err);
      }
    },
    groupByProvince(array, key) {
      return array.reduce(function(rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    }
  },
  mounted() {
    this.fetchAddressData();
  }
};
</script>
